import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const PrivacyPolicyPage = ({ location, data: { contentfulSitePage } }) => {
  return (
    <Layout>
      <SEO pathname={location.pathname} title={contentfulSitePage.title} />

      <div className="relative w-11/12 max-w-screen-md mx-auto py-24">
        <h1 className="font-bold text-2xl text-center mb-24">{contentfulSitePage.title}</h1>

        <div
          dangerouslySetInnerHTML={{ __html: contentfulSitePage.content?.childMarkdownRemark.html }}
          className="rich-text"
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PrivacyPolicyPage($slug: String!) {
    contentfulSitePage(slug: { eq: $slug }) {
      slug
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default PrivacyPolicyPage;
